




































































































.terms {
  max-height: 50vh;
  overflow-y: auto;
}
