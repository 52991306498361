















































































































































































.nav-tabs-style {
  display: none !important;
}
