



















#planorama-logo {
  width: 100%;
  margin-bottom: 1rem;
}
