































































.survey {
  .form-group {
    max-width: 60rem;
  }
}
