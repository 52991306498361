































































































































































































































































.col-name-field div {
  width: 8rem;
}
