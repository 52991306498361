





















.truncated-tooltip {
  .tooltip-inner {
    max-width: 30rem;
    white-space: pre-wrap;
  }
}
.truncated-span {
  max-width: 15rem;
  display: inline-block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
