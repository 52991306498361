






























































.survey-controls {
  box-shadow: 5px 5px 10px gray;
  background-color: white;
  top: 30%;
  right: 4rem;
  z-index: 1000;
}
