























































@media (min-width: 576px) {
  #mobile-navbar {
    display: none !important;
  }
}

.navbar.navbar-dark .border-top {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.navbar-brand.bigger {
  font-size: 2rem;
  font-weight: 500;
}
