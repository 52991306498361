





























.configuration.enable .form-row {
  align-items: center;
}

.basis-14 {
  flex-basis: 14rem;
}
