/*
 *
 */
@import 'custom.scss';
$sidebar-width: 35%;
$sidebar-mobile-width: 60%;

dt {
  font-weight: normal !important;
}

.first-capital {
  text-transform: capitalize;
}

// .capitalize {
//   text-transform: uppercase;
// }

.keep-format {
  white-space: pre-wrap;
}

#mobile-navbar {
    &.not-production {
      background-color: #ec6fde !important;
    }
    border-bottom: 2px solid $color-primary-4;
}


.navbar.navbar-light {
  background: linear-gradient(to right, #ffffff, $color-primary-4 67%);
  border-bottom: 5px solid $color-primary-0;
  .navbar-brand {
    font-size: 3rem;
    color: $color-secondary-2-3;
    font-weight: bolder;
  }
  &.not-production {
    background: linear-gradient(to right, #ffffff, #f311d8 67%); 
  }
}

@media (min-width: 576px) {
  .navbar.navbar-light {
    height: 100px;
  }

  #side-navbar .nav {
    height: calc(100vh - 150px);
  }

  .scrollable {
    max-height: calc(100vh - 150px);
    overflow-x: auto;
    &.minus31 {
      max-height: calc(100vh - 181px);
    }
  }
}

@media (max-width: 575px) {
  #top-navbar, #side-navbar {
    display: none !important;
  }

  body {
    overflow: visible;
  }
}

#side-navbar .nav {
  background: $color-light-blue;
  overflow-y: auto;
  .nav-link.active {
    background: $color-primary-2;
    color: white;
  }
}

#bottom-navbar footer.not-production {
  background: #f311d8;
}

.description-tooltip .tooltip-inner {
  max-width: 30rem;
}
.scrollable {
  overflow-y: auto;
  padding-right: 1em;
  &.minus31 {
    padding-right: 0;
  }
}

#planotoast {
  position: absolute;
  right: 5px;
  top: 105px;
}

// why do i have to do this?!
body {
  overflow-x: hidden;
}

/* for CKEditor */
.cke_button_label.cke_button__insert-plano-email-fields_label {
  display: inline;
}

.plono-ck-button .cke_button_icon {
  display: none;
}

.vqb-custom-component-wrap {
  flex: 1 1 auto;
}

/*  make the ticky switch controls more colorful */
.custom-switch {
  .custom-control-label {
    &:after {
      background-color: $color-primary-0;
    }

    &:before {
      border: solid 1px $color-primary-0;
    }
  }

  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    &:before {
      border: solid 1px $color-primary-disabled;
    }

    &:after {
      background-color: $color-primary-disabled;
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label:after ,
  .custom-control-input[disabled]:checked ~ .custom-control-label:after {
    background-color: rgba(white, 0.72);
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color}.alert-bright {
    @include alert-variant(theme-color-level($color, 0), theme-color-level($color, 0), theme-color-level($color, $alert-color-level));
  }
}

.flex-basis-50 {
  flex-basis: 50%;
}

// make disabled link buttons italic
.btn-link:disabled, .btn-link.disabled {
  font-style: italic;
}
