












































.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

