









































































































































































































#reg-sync-modal {
  min-height: calc(100% - 6.75rem);
  max-height: calc(100% - 6.75rem);
  margin: 1.75rem auto 5rem;
  .modal-content {
    min-height: 100%;
    max-height: 100%;
  }
}

.name-span {
  min-width: 16rem;
  text-align: center;
}

.scrollable-region {
  max-height: 332px;
  overflow-y: scroll;
}
