/**
customizing bootstrap colors
*/

$color-primary-0: #646eca; // Main Primary color */
$color-primary-1: #b8bef1;
$color-primary-2: #8a92df;
$color-primary-3: #4650b2;
$color-primary-4: #2c37a1;

$color-secondary-1-0: #865fc8; // Main Secondary color (1) */
$color-secondary-1-1: #ccb5f0;
$color-secondary-1-2: #a785de;
$color-secondary-1-3: #6a40b0;
$color-secondary-1-4: #53269f;

$color-secondary-2-0: #569bc0; // Main Secondary color (2) */
$color-secondary-2-1: #b1d9ee;
$color-secondary-2-2: #7eb9d9;
$color-secondary-2-3: #377ea4;
$color-secondary-2-4: #1e6b94;

$color-complement-0: #ffd76b; // Main Complement color */
$color-complement-1: #ffecba;
$color-complement-2: #ffe08f;
$color-complement-3: #ffce4c;
$color-complement-4: #e9b425;

$primary: $color-primary-0;
$secondary: $color-primary-0;
$info: $color-secondary-2-0;
$warning: $color-complement-0;

$color-light-blue: rgba(184,189,241,0.15);
$color-primary-disabled: rgba(100, 110, 202, 0.5);

// Make everything a bit smaller (as per AB's request)
* {
   font-size: 14px;
   line-height: 1.428;
}

@import "node_modules/bootstrap/scss/bootstrap";
