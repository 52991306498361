












































.help-modal .modal-md{
  max-width: 20rem;
}
