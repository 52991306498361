






























































































































































































.survey-page {
  max-width: 50rem;
  padding-bottom: 10px;
}
