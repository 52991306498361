









































































































































































form {
  label {
    display: block;
  }
  .input-line {
    width: 60%;
  }
}
.display-sync-data {
  margin-top: 1.5rem;
  min-height: 19rem;
  padding-top: 0.5rem;
  border-top: 1px solid #dee2e6;
}
