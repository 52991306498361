

























































.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}

.schedule-title {
  background-color: #ffffff;
  border: 0 0 1px 0;
  border-color: #666666;
}

.card {
  border: 0;
}

.pipe-border {
  padding: 0.5rem 0.75rem;
  border-left: 2px solid gray;
  min-height: 2.5rem;
  min-width: 5rem;
  flex-basis: 5rem;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 700;
}

.larger-text {
  font-size: 16px;
}
