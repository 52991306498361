





















































































































































































.session-conflicts-list {
  overflow-y: auto;
  max-height: 300px;
}

.session-conflict:first-child {
  margin-top: 1em;
}

.session-conflict:not(:first-child) {
  border-top: 1px solid rgba(0,100,150,.15);
}

