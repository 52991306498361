






















































































































.accordion {
  max-width: 60rem;
}
